import { render, staticRenderFns } from "./pre-heading.vue?vue&type=template&id=85e5a466&scoped=true"
import script from "./pre-heading.vue?vue&type=script&lang=js"
export * from "./pre-heading.vue?vue&type=script&lang=js"
import style0 from "./pre-heading.vue?vue&type=style&index=0&id=85e5a466&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85e5a466",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VContainer})
